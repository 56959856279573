import fallbackImg from '../../images/image.svg'
import { theme } from '../utils'
export const searchStyles = () => ({
  container: (styles) => ({
    ...styles,
    margin: '8px 0',
    borderRadius: '10px',
    cursor: 'pointer',
    fontSize: '1rem',
  }),
  input: (styles) => ({
    ...styles,
    fontSize: '1.125rem',
  }),
  inputValue: (styles) => ({
    ...styles,
    fontSize: '1rem',
  }),
  menu: (styles) => ({
    ...styles,
    top: 0,
    position: 'relative',
    borderRadius: '10px',
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    borderRadius: '10px',
    borderColor: 'white',
    boxShadow: '0 0 1px #e23580',
    ':hover': {
      borderColor: '#e23580',
      boxShadow: '0 0 1px #e23580',
    },
    ':active': {
      borderColor: '#e23580',
      boxShadow: '0 0 1px #e23580',
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: '1rem',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  option: (styles, { data, isFocused, isSelected }) => {
    return {
      ...styles,
      fontWeight: 550,
      fontFamily: theme.font.primary,
      display: 'grid',
      gridTemplateColumns: 'auto 1fr auto',
      columnGap: 16,
      alignItems: 'center',
      cursor: 'pointer',
      width: 'auto',
      margin: '4px 8px',
      borderRadius: '8px',
      backgroundColor: isFocused
        ? 'rgba(226, 53, 128,0.15)'
        : isSelected
        ? 'rgba(226,53,128,0.25)'
        : null,
      color: isFocused
        ? theme.color.pink600
        : isSelected
        ? theme.color.pink600
        : theme.primary,

      ':before': {
        borderRadius: 2,
        content: '" "',
        backgroundImage: `url(${data.imageUrl ? data.imageUrl : fallbackImg})`,
        display: 'block',
        marginRight: 8,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        height: 48,
        width: 48,
      },
      ':after': {
        content: `"${data.difference}"`,
        backgroundColor: data.isLower ? '#95ffb8' : '#f3aecc',
        color: data.isLower ? '#012407' : '#88204d',
        fontSize: '0.9rem',
        fontWeight: 500,
        padding: ' 4px 8px',
        margin: '0 2px',
        borderRadius: '20px',
      },
    }
  },
})
