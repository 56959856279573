import React, { useState, useEffect, useContext } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import Cookie from 'universal-cookie'
import { Loader } from '../Loader/Loader'
import { FeedbackForm } from '../Feedback/FeedbackForm'
import { Share } from './Share'
import { BuildComponent } from './BuildComponent'
import {
  buildFetchSplitup,
  ComponentContext,
  fetchPostOptions,
  MetaComponentContext,
  toEuro,
} from '../helpers'
import { Seo, Typography, rgba } from '../utils'
import { AlertTriangle, ChevronDown } from 'react-feather'

const cookie = new Cookie()

export default function Build({ location, imageData }) {
  const [contextComponents, setGlobalComponents] = useContext(ComponentContext)
  const [metaComponents, setMetaComponents] = useContext(MetaComponentContext)
  const [isLoading, setLoading] = useState(true)
  const [urlId, setUrlId] = useState('')

  const url = process.env.GATSBY_GHOSTBUILDER_LOAD_API

  useEffect(() => {
    setUrlId(location.pathname.match(/\d+[0-9]/)[0])
    async function fetchData() {
      try {
        const res = await fetch(url, {
          ...fetchPostOptions,
          body: JSON.stringify({
            enteredBuild: location.pathname.match(/\d+[0-9]/)[0],
          }),
        })
        const json = await res.json()
        const { build, buildMetadata } = buildFetchSplitup(json)
        setLoading(false)
        setGlobalComponents(build)
        setMetaComponents(buildMetadata)
      } catch (error) {
        navigate('/something-went-wrong/')
      }
    }

    if (!location.state?.buildData) {
      fetchData()
    } else {
      setGlobalComponents(location.state.buildData)
      // console.log(location.state.buildData)
      setMetaComponents(location.state.buildMetadata)
      setLoading(false)
    }
  }, [url, isLoading, setGlobalComponents, setMetaComponents, location])

  useEffect(() => {
    if (cookie.get('isCookieAccepted')) {
      cookie.set('buildUrl', window.location.href, { expires: 0 })
    }
  }, [])

  return (
    <>
      <Seo
        title={`Deine persönliche Konfiguration
          ${metaComponents && ' für ' + toEuro(metaComponents.buildPrice)}`}
      />
      <Background>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Wrapper>
              {location.state?.isNewConfig && (
                <Notice as="details">
                  <Typography as="summary">
                    <span class="notice-text">
                      <AlertTriangle />
                      <span>
                        Konfiguration nicht möglich,
                        <wbr /> Budget wurde gesenkt.
                      </span>
                    </span>
                    <ChevronDown />
                  </Typography>
                  <Typography>
                    Der ghostbuilder konnte mit dieser Eingabe leider keinen PC
                    in deinem gewünschten Budget zusammenstellen. Das kann an
                    schlechter Teileverfügbarkeit, zu hohen Preisen oder zu
                    detaillierten Einstellungen liegen.
                    <br />
                    Das neue Budget beträgt {toEuro(metaComponents.buildPrice)}
                  </Typography>
                </Notice>
              )}
              <Grid>
                <Heading variant="h1">Build</Heading>
                <TotalPrice variant="h2">
                  {metaComponents && toEuro(metaComponents.buildPrice)}
                </TotalPrice>
                {contextComponents &&
                  contextComponents.map((element) => (
                    <BuildComponent
                      key={element.affiliateLink}
                      imageUrl={element.imageUrl}
                      type={element.type}
                      name={element.name}
                      price={element.price}
                      provider={element.provider}
                      affiliateLink={element.affiliateLink}
                      partInfo={element.partInfo}
                      isPlaceholder={element.placeholder}
                      location={location}
                      // isExpanded={isExpanded}
                      // setExpanded={() => setExpanded(!isExpanded)}
                    />
                  ))}
              </Grid>
              <Grid>
                <Share
                  shareLink={`https://ghostbuilder.io/build/${urlId}`}
                  gridColumn="1 / 5"
                />
              </Grid>
              <Grid>
                <FeedbackForm
                  shareLink={`https://ghostbuilder.io/build/${urlId}`}
                  gridColumn="1 / 5"
                  style={{ margin: '0 1rem' }}
                  hasHeading
                />
              </Grid>
            </Wrapper>
          </>
        )}
      </Background>
    </>
  )
}

const Background = styled.div`
  background: radial-gradient(
      21.45% 42.43% at 29.93% 43.99%,
      #583fa0 0%,
      rgba(115, 89, 191, 0) 100%
    ),
    linear-gradient(112.97deg, #0e99d4 5.81%, rgba(14, 153, 212, 0) 38.8%),
    radial-gradient(
      29.76% 49.52% at 88.92% 92.72%,
      #ffb659 11.46%,
      rgba(232, 122, 94, 0) 100%
    ),
    linear-gradient(180deg, #4b368b 0%, #e8619c 100%);
  padding-top: 5rem;
`

const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  margin: 2rem auto 0;
  max-width: 420px;
  @media (min-width: 768px) {
    max-width: 1024px;
    /* margin: 2rem auto ; */
  }
  /* padding-bottom: 3rem; */
  padding: 0 0.5rem 3rem;
`

const Box = styled.div`
  border-radius: 16px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.25)
  );
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 16px 16px 50px 0 rgb(0 0 0 / 10%);
  margin-bottom: 1rem;
  padding: 1rem;
`

const Notice = styled(Box)`
  background: ${({ theme }) => `repeating-linear-gradient(
                    45deg,
                    ${rgba(theme.color.warningBackground, 0.25)},
                    ${rgba(theme.color.warningBackground, 0.25)} 20px,
                    ${rgba(theme.color.warningBackground, 0.2)} 20px,
                    ${rgba(theme.color.warningBackground, 0.2)} 40px
                  )`};
  color: white;
  cursor: pointer;
  padding: 0.5rem 1rem;

  summary,
  .notice-text {
    color: ${({ theme }) => theme.color.warningBackground};
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    line-height: 150%;
    width: 100%;
    .notice-text {
      padding-top: 2px;
    }
  }

  p {
    margin-block: 0.5rem;
  }

  @media (min-width: 768px) {
    summary {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
`

const Grid = styled(Box)`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, minmax(4rem, 1fr));
  column-gap: 1rem;
  padding: 1rem 0;
  margin-bottom: 4rem;

  @media (min-width: 1280px) {
    column-gap: 2rem;
    padding: 1rem;
  }
`
const Heading = styled(Typography)`
  grid-column: 1 span;
  justify-self: start;
  margin-left: 1rem;
`
const TotalPrice = styled(Typography)`
  grid-column: 3 / 5;
  align-self: end;
  justify-self: end;
  margin-right: 1rem;
  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: normal;
`
