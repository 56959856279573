import React from 'react'
import styled from 'styled-components'
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
} from 'react-share'
import { Share as ShareFeather } from 'react-feather'
import useClipboard from 'react-use-clipboard'
import { Toast } from '../Toast/Toast'
import { IconButton, Input, Typography } from '../utils'

export const Share = ({ shareLink, gridColumn }) => {
  const [isCopied, setCopied] = useClipboard(shareLink, {
    successDuration: 2000,
  })

  const text =
    'Schau dir meinen zusammengestellten Computer von Ghostbuilder.io an '

  function handleShare() {
    if (navigator.share) {
      navigator
        .share({
          title: 'web.dev',
          text: 'Check out web.dev.',
          url: 'https://web.dev/',
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error))
    }
  }

  return (
    <>
      <Container gridColumn={gridColumn}>
        <Typography variant="h2">Teile deine Konfiguration</Typography>
        <ButtonContainer>
          <FacebookShareButton
            role="option"
            url={shareLink}
            quote={text}
            hashtag="ghostbuilder"
          >
            <FacebookIcon round role="img" />
          </FacebookShareButton>
          <TwitterShareButton
            role="option"
            url={shareLink}
            title={text}
            hashtags={['ghostbuilder']}
          >
            <TwitterIcon round role="img" />
          </TwitterShareButton>
          <WhatsappShareButton role="option" url={shareLink} title={text}>
            <WhatsappIcon round role="img" />
          </WhatsappShareButton>
          <TelegramShareButton role="option" url={shareLink} title={text}>
            <TelegramIcon round role="img" />
          </TelegramShareButton>
          <RedditShareButton role="option" url={shareLink} title={text}>
            <RedditIcon round role="img" />
          </RedditShareButton>
          <NativeShareButton
            role="options"
            onClick={handleShare}
            style={{
              width: 44,
            }}
          >
            <ShareIcon />
          </NativeShareButton>
        </ButtonContainer>
        <Input
          type="url"
          id="shareUrl"
          name={shareLink}
          defaultValue={shareLink}
          readOnly={true}
          copyToClipboard
          setCopied={setCopied}
        />
      </Container>
      <Toast
        isVisible={isCopied}
        style={{
          color: `#012407`,
          background: `#95ffb8`,
          border: '1px solid #012407',
        }}
      >
        Link wurde kopiert
      </Toast>
    </>
  )
}

const Container = styled.div`
  grid-column: ${({ gridColumn }) => gridColumn};
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  margin: 0 1rem;
`
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  button {
    margin: 0 0.25rem;
    width: 3rem;
    display: inline-flex;
    align-items: center;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  & > button:first-of-type {
    margin-left: 0px;
  }
`

const NativeShareButton = styled(IconButton)`
  border-radius: 2rem;
`
const ShareIcon = styled(ShareFeather)``
