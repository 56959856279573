import React, { useState, useEffect, useContext } from 'react'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import {
  AlertTriangle,
  ChevronDown,
  ExternalLink,
  ShoppingCart,
} from 'react-feather'
import AsyncSelect, { createFilter } from 'react-select'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import useMedia from 'use-media'
import {
  infoParser,
  ComponentContext,
  MetaComponentContext,
  fetchPostOptions,
  toEuro,
} from '../helpers'
import { IconButton, Link, LinkButton, rgba, Typography } from '../utils'
import { searchStyles } from './SearchStyles'
import fallbackImg from '../../images/image.svg'

const PlaceholderArcticle =
  '/blog/platzhalter-im-ghostbuilder-warum-sie-da-sind-und-was-sie-machen/'

export const BuildComponent = ({
  imageUrl,
  type,
  name,
  price,
  provider,
  isPlaceholder,
  affiliateLink,
  partInfo,
  location,
  gridColumn,
}) => {
  const isLargeScreen = useMedia({ minWidth: 768 })
  const [isExpanded, setExpanded] = useState(false)
  const [buildComponent, setBuildComponent] = useState({})
  const [searchComponents, setSearchComponents] = useState([])
  const [newComponents, setNewComponents] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const parsedInfos = partInfo ? infoParser(partInfo) : null
  const [searchComponentValue, setSearchComponentValue] = useState('')

  const { pathname } = useLocation()

  const [contextComponents, setGlobalComponents] = useContext(ComponentContext)
  const [, setMetaComponents] = useContext(MetaComponentContext)

  const url = process.env.GATSBY_GHOSTBUILDER_SWAP_API
  const options = {
    ...fetchPostOptions,
    body: JSON.stringify({
      buildId: location.pathname.match(/\d+[0-9]/)[0],
      component: type,
      search: true,
    }),
  }

  useEffect(() => {
    setExpanded(false)
  }, [pathname])

  useEffect(() => {
    setBuildComponent({
      imageUrl: imageUrl,
      partInfo: partInfo,
      name: name,
      price: price,
      provider: provider,
      isPlaceholder: isPlaceholder,
      affiliateLink: affiliateLink,
      type: type,
    })
  }, [
    imageUrl,
    partInfo,
    name,
    price,
    provider,
    isPlaceholder,
    affiliateLink,
    type,
  ])

  useEffect(() => {
    newComponents.forEach(
      ({ name, affiliateLink: id, imageUrl, price: newPrice, ...rest }) => {
        const isLower =
          parseFloat(newPrice) - parseFloat(price) < 0 ? true : false
        const difference = parseFloat(newPrice) - parseFloat(price)
        setSearchComponents((prev) => [
          ...prev,
          {
            label: name,
            name: name,
            value: id,
            affiliateLink: id,
            imageUrl: imageUrl,
            price: newPrice,
            difference: `${isLower ? '' : '+'}${toEuro(difference)}`,
            isLower: isLower,
            ...rest,
          },
        ])
      }
    )
  }, [newComponents, price])

  const fetchSwap = async () => {
    try {
      const response = await fetch(url, options)
      const body = await response.json()
      setNewComponents(body)
      setLoading(false)
    } catch (err) {
      setError(error)
    }
  }

  const swapComponents = (item) => {
    const contextRef = contextComponents
    const swapRef = newComponents

    const current = contextRef.find(
      (contextItem) => contextItem.name === buildComponent.name
    )
    const currentIndex = contextRef.indexOf(current)
    const swapItem = swapRef.find((swapItem) => swapItem.name !== item.name)
    const swapIndex = contextRef.indexOf(swapItem)
    contextRef.splice(currentIndex, 1)
    contextRef.splice(currentIndex, 0, item)
    swapRef.splice(swapIndex, 1)
    const buildPrice = contextRef
      .map((element) => element.price)
      .reduce((acc, item) => acc + item)
      .toFixed(2)
    setGlobalComponents(contextRef)
    setMetaComponents((prev) => ({
      ...prev,
      buildID: item.buildID,
      buildPrice: buildPrice,
    }))
    setNewComponents(swapRef.splice(swapIndex, 0, current))
    navigate(`/build/${item.buildID}`, {
      state: {
        buildData: contextRef,
        buildMetadata: {
          buildID: item.buildID,
          buildPrice: buildPrice,
        },
      },
    })

    setExpanded(false)
  }

  const updatedComponents = (selectedItem) => {
    swapComponents(selectedItem)
  }

  const handleInputChange = (inputValue, action) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      setSearchComponentValue(inputValue)
    }
  }

  return (
    <Container gridColumn={gridColumn} isPlaceholder={isPlaceholder}>
      <ImgWrapper
        href={`${affiliateLink}`}
        target="_blank"
        rel="noopener noreferrer nofollow"
        className="build-img-container"
      >
        <motion.img
          alt={`${name} Component`}
          srcSet={imageUrl}
          src={fallbackImg}
        />
      </ImgWrapper>

      <NameWrapper>
        <Typography variant="h3" id="build-componentName">
          {name}
        </Typography>
      </NameWrapper>

      {!isLargeScreen ? (
        <ShopButton
          variant="outlined"
          size="small"
          fullWidth
          to={affiliateLink}
          target="_blank"
          rel="noopener noreferrer"
          isPlaceholder={provider === 'Platzhalter'}
        >
          {provider}
          <br />
          {toEuro(price)}
        </ShopButton>
      ) : (
        <>
          <TextWrapper>
            {provider !== 'Platzhalter' ? (
              <>
                <Typography variant="label" htmlFor="build-providerName">
                  Anbieter
                </Typography>
                <Link
                  to={affiliateLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="rgba(255,255,255,0.5)"
                >
                  <ShoppingCart />
                  {provider}
                </Link>
              </>
            ) : (
              <PlaceholderWrapper>
                <Typography>
                  <AlertTriangle style={{ marginRight: 4 }} size={20} />
                  Achtung Platzhalter!
                </Typography>
                <Link
                  to={PlaceholderArcticle}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Erfahre hier mehr.
                </Link>
              </PlaceholderWrapper>
            )}
          </TextWrapper>
          <TextWrapper>
            <Typography variant="label" htmlFor="build-priceTag">
              Preis
            </Typography>

            {provider !== 'Platzhalter' ? (
              <Link
                to={affiliateLink}
                target="_blank"
                rel="noopener noreferrer"
                color="rgba(255,255,255,0.7)"
              >
                {toEuro(price)}
              </Link>
            ) : (
              <Typography>{toEuro(price)}</Typography>
            )}
          </TextWrapper>
          {provider !== 'Platzhalter' && (
            <ShopButtonLarge
              // variant="filled"
              variant="outlined"
              size="small"
              to={affiliateLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Zu {provider} <ExternalLink />
            </ShopButtonLarge>
          )}
        </>
      )}
      {parsedInfos && (
        <MoreInfo
          isExpanded={isExpanded}
          setExpanded={setExpanded}
          fetchSwap={fetchSwap}
        />
      )}

      {isExpanded && parsedInfos && (
        <InfoWrapper>
          {parsedInfos.map((item) => (
            <React.Fragment key={`${name} Info ${item.index}`}>
              <Typography variant="label">{item.label}:</Typography>
              <Typography>{item.value}</Typography>
            </React.Fragment>
          ))}
        </InfoWrapper>
      )}
      {isExpanded && (
        <AdditionalWrapper>
          {error ? (
            <Typography>
              Es trat ein Fehler beim Laden von weiteren Optionen auf.
            </Typography>
          ) : (
            <>
              <Typography
                variant="label"
                htmlFor={`additionalComponent-${name}`}
              >
                weitere Optionen:
              </Typography>
              <AsyncSelect
                id={`additionalComponent-${name}`}
                role="listitem"
                aria-label="Additional Component Searchbar"
                className="build-additional-search-container"
                inputValue={searchComponentValue}
                options={searchComponents}
                styles={searchStyles(imageUrl)}
                isLoading={isLoading}
                placeholder="Suche nach weiteren Optionen..."
                filterOption={createFilter({
                  ignoreCase: true,
                  ignoreAccents: true,
                })}
                onChange={updatedComponents}
                onInputChange={handleInputChange}
                blurInputOnSelect={false}
                isSearchable
                menuIsOpen
                closeMenuOnSelect
              />
            </>
          )}
        </AdditionalWrapper>
      )}
    </Container>
  )
}

const MoreInfo = ({ isExpanded, setExpanded, fetchSwap, large = false }) => (
  <MoreInfoButton
    onClick={() => {
      setExpanded(!isExpanded)
      !isExpanded && fetchSwap()
    }}
    variant="outlined"
  >
    {large && 'mehr Infos'}
    <MoreInfoIcon
      animate={{
        rotate: isExpanded ? 180 : 0,
      }}
    />
  </MoreInfoButton>
)

const Container = styled(motion.section)`
  position: relative;
  display: grid;
  align-items: center;
  grid-column: ${({ gridColumn }) => (gridColumn ? gridColumn : 'span 4')};
  grid-template-columns: 100px 1fr auto;
  column-gap: 1rem;
  row-gap: 0.5rem;
  padding: 0.75rem 1rem 1rem;
  border-radius: 12px;
  @media (min-width: 768px) {
    row-gap: 1rem;
    grid-template-columns:
      minmax(140px, 150px) minmax(160px, 220px) minmax(100px, 140px)
      auto 48px;
  }
`

const ImgWrapper = styled(motion(OutboundLink))`
  grid-column: 1;
  grid-row: 1 / 3;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100px;
  height: 100px;

  background: white;
  border-radius: 8px;

  img {
    width: 90%;
    height: 90%;
    object-fit: contain;
  }
  @media (min-width: 768px) {
    min-width: 135px;
    min-height: 135px;
    max-width: 150px;
    max-height: 150px;
  }
`

const NameWrapper = styled.div`
  grid-column: 2 / 5;
  grid-row: 1;
  align-self: flex-end;
  @media (max-width: 767px) {
    grid-column: 2 / 4;
    h3 {
      font-size: 1.25rem;
    }
  }
`

const ShopButton = styled(LinkButton)`
  grid-column: 2;
  grid-row: 2;
  line-height: 100%;
  padding: 0.35rem 0.8rem;
  background: transparent;

  ${({ isPlaceholder }) => {
    if (isPlaceholder) {
      return css`
        background: ${({ theme }) => `repeating-linear-gradient(
                    45deg,
                    ${rgba(theme.color.primary, 0.3)},
                    ${rgba(theme.color.primary, 0.3)} 10px,
                    ${rgba(theme.color.warningBackground, 0.25)} 10px,
                    ${rgba(theme.color.warningBackground, 0.25)} 20px
                  )`};
        color: white;
      `
    }
  }}
`
const ShopButtonLarge = styled(LinkButton)`
  grid-column: 4;
  grid-row: 2;
  /* font-size: 1rem; */
  justify-self: flex-end;
  svg {
    margin-left: 8px;
  }
  color: white;
  &:visited {
    color: white;
  }
  background-color: transparent;
  padding: 0.5rem 1rem;
`

const TextWrapper = styled.div`
  grid-row: 2;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0.25rem;
  align-items: center;
  align-self: center;

  svg {
    margin-right: 0.5rem;
    height: 1.125rem;
    width: 1.125rem;
  }
  label {
    color: ${({ theme }) => rgba(theme.color.white, 0.7)};
    font-size: 1.125rem;
  }
  a,
  a:visited {
    color: ${({ theme }) => rgba(theme.color.white, 1)};
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => rgba(theme.color.white, 1)};
  }
  a {
    font-size: 1.25rem;
    padding: 0.25rem 0 0rem;
    font-family: ${({ theme }) => theme.font.secondary};
  }
`

const PlaceholderWrapper = styled.div`
  width: 100%;
  grid-row: 3;
  grid-column: span 4;
  padding: 0.2rem 0.5rem;
  text-align: right;
  border-radius: 4px;
  background: ${({ theme }) => `repeating-linear-gradient(
    45deg,
    ${rgba(theme.color.warningBackground, 0.3)},
    ${rgba(theme.color.warningBackground, 0.3)} 10px,
    ${rgba(theme.color.warningBackground, 0.25)} 10px,
    ${rgba(theme.color.warningBackground, 0.25)} 20px
  )`};
  /* background: ${({ theme }) => rgba(theme.color.warningBackground, 0.3)}; */
  p {
    color: ${({ theme }) => theme.color.warningBackground};
  }
  p,
  a,
  a:visited {
    font-size: 0.8rem;
    font-weight: 600;
  }

  @media (min-width: 768px) {
    grid-column: span 2;
    width: fit-content;
  }
`

const MoreInfoButton = styled(IconButton)`
  grid-column: 3;
  grid-row: 2;
  @media (min-width: 768px) {
    grid-column: 5;
  }
  justify-self: flex-end;
  background: transparent;
`

const InfoWrapper = styled.div`
  grid-column: 1 / 4;
  margin: 1rem 0 0.5rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: end;
  row-gap: 0.5rem;
  p {
    line-height: 100%;
  }
  label {
    color: ${({ theme }) => theme.color.labelText};
    text-transform: capitalize;
    margin-right: 1rem;
    word-wrap: normal;
    font-weight: 500;
  }
  @media (min-width: 768px) {
    grid-column: 2 / 5;
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(6, minmax(100px, 150px));
  }
`
const AdditionalWrapper = styled.div`
  grid-column: 1 / 4;
  label {
    color: ${({ theme }) => theme.color.labelText};
  }
  @media (min-width: 768px) {
    grid-column: 2 / 5;
  }
`

const MoreInfoIcon = motion(ChevronDown)
