import { graphql } from 'gatsby'
import React, { useState } from 'react'
import Build from '../components/Build/Build'
import { ComponentContext, MetaComponentContext } from '../components/helpers'

export default function BuildPage({ location, data }) {
  const { build } = data

  const [contextComponent, setGlobalComponents] = useState()
  const [metaComponent, setMetaComponents] = useState()
  return (
    <ComponentContext.Provider value={[contextComponent, setGlobalComponents]}>
      <MetaComponentContext.Provider value={[metaComponent, setMetaComponents]}>
        <Build location={location} imageData={build} />
      </MetaComponentContext.Provider>
    </ComponentContext.Provider>
  )
}

export const buildQuery = graphql`
  {
    build: sanityAdBannerSettings(domainAppearence: { eq: "build" }) {
      link
      desktop: desktop {
        alt
        asset {
          gatsbyImageData
        }
      }
      mobile: mobile {
        alt
        asset {
          gatsbyImageData
        }
      }
    }
  }
`
